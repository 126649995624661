import React from 'react'
import { Link } from "gatsby"

import Seo from '~/components/seo'
import Arrow from "~/images/common_icon_arrow_r.svg";

const ContactPage = () => (
  <>

    <Seo title="お問い合わせ" pageUrl="contact" />
    <div className="pageComponent">
      <nav className="contentComponent">
        <ul className="breadcrumbComponent mincho">
          <li><Link to="/" itemProp="url">TOP</Link> <span><Arrow /></span></li>
          <li>Contact</li>
        </ul>
      </nav>
      <article
        className="contact"
        itemScope
        itemType="http://schema.org/Article">
        <header className="titleComponent">
          <h1 className="contentComponent heading with-english"><span>お問い合わせ</span>Contact</h1>
          <hr className="borderLine"/>
        </header>
        <div className="contentComponent">
          <p className="heading is-secondary">修理作業の合間でのお返事となりますため、お返事まで3営業日以上のお日にちをいただく場合がございます。<br className="hidden-phone" />ご了承くださいませ。<br className="visible-phone" /><span className="smallTxt">（※商品の不具合などお急ぎの場合を除く）</span></p>
          <ul className="linkListComponent">
            <li>
              <Link to="/contact-order/">
                <span>ご注文前のお問い合わせ</span>
              </Link>
            </li>
            <li>
              <Link to="/contact-deliver/">
                <span>ご注文時の配送に関するお問い合わせ</span>
              </Link>
            </li>
            <li>
              <Link to="/contact-repair/">
                <span>ご注文時の修理等に関するお問い合わせ</span>
              </Link>
            </li>
            <li>
              <Link to="/contact-other/">
                <span>その他のお問い合わせ</span>
              </Link>
            </li>
          </ul>
          <p className="heading is-secondary">ご質問はLINEでもお受けしております。<br className="visible-phone" />以下の「LINEで相談する」よりお問い合わせください。</p>
        </div>
      </article>
    </div>

  </>
)

export default ContactPage
